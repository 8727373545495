import { Box, Button, Card, Flex, Paragraph, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { padding } from "polished"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { happinessSurveySelectors } from ".."
import { CountriesLoader, IndustriesLoader, SurveyJobRolesLoader } from "../../misc"
import SelectShaped from "../../ui/components/SelectShaped"
import SiteLogo from "../../ui/components/SiteLogo"
import Loader from "../containers/Loader"
import { SponsorLogos as logos } from "../lib/VoiceAwardsLibrary"
import SponsorLogo from "./SponsorLogo"
import VoiceAward from "./VoiceAward"

const CardWrapper = styled(Card)`
  @media screen and (max-width: 1280px) {
    max-width: 580px;
  }
  @media screen and (min-width: 1281px) {
    min-width: 580px;
    max-width: 750px;
    & > div:nth-child(2),
    & > div:nth-child(3) > div {
      justify-content: space-around;
    }
  }
`

const StyledOl = styled.ol`
  @media screen and (max-width: 350px) {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  @media screen and (min-width: 351px) {
    padding: 0px 20px;
    margin: 3px 0;
  }
`
const NewButton = styled(Button)`
  height: 38px;
  filter: drop-shadow(0px 4px 4px 0px #00000040);
  border: 4px solid #00e96e;
  border-radius: 9px;
`

const happinessSurveyId = () => getConfig().surveyIds.whs

const ContentWrapper = styled(Box)`
  & > p:last-child {
    margin-bottom: 0;
  }
`

const SurveyDifference = ({ t, startSurvey }) => {
  const history = useHistory()
  const paths = usePaths()

  const isInAppView = history.location.pathname.includes("/in-app-view")
  const linkPath = isInAppView
    ? `${paths.inAppViewOrganisationSurvey.index.replace(":id", getConfig().surveyIds.wellbeing)}`
    : `${paths.survey.index.replace(":id", getConfig().surveyIds.wellbeing)}`

  return (
    <Flex
      bg="light.0"
      px={4}
      pb={4}
      flexDirection={["column", "row"]}
      justifyContent={["center", "space-between"]}
      alignItems="center"
      width="100%"
    >
      <NewButton
        variant="primary.0"
        width={["238px", "262px"]}
        mb={["3", "0"]}
        fontSize={["12px", "14px"]}
        onClick={() => {
          if (typeof window !== "undefined" && window.gtag) {
            window.gtag("event", "happy_at_work_test", {
              event_category: "button_click",
              event_label: "Happy at Work Test"
            })
          }
          startSurvey(`${getConfig().surveyIds.whs}`)
        }}
      >
        {t("choose.wfh_button", "")}
      </NewButton>
      <NewButton
        variant="primary.0"
        width={["238px", "262px"]}
        fontSize={["12px", "14px"]}
        onClick={() => {
          if (typeof window !== "undefined" && window.gtag) {
            window.gtag("event", "well_being_test", {
              event_category: "button_click",
              event_label: "Wellbeing Test"
            })
          }
          window.open(linkPath, "_blank", "noopener")
        }}
      >
        {t("choose.whs_button", "")}
      </NewButton>
    </Flex>
  )
}

const Welcome = ({ languages, onLoad }) => {
  const history = useHistory()
  const paths = usePaths()
  const router = useRouter()

  let returnTo = paths.home
  // Pass in a URL parameter to force return path. For example, ?returnTo=/develop-career
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    const returnToURL = urlParams.get("returnTo")
    if (returnToURL) {
      returnTo = returnToURL
    }
  }
  const closeSurvey = () => router.navigate(returnTo)
  const startSurvey = id => {
    onLoad(id)
    if (history.location.pathname.includes("/in-app-view")) {
      router.navigate(paths.inAppViewHappinessSurvey.rankYourCompany)
    } else {
      router.navigate(paths.happinessSurvey.rankYourCompany)
    }
  }
  const { t, i18n } = useTranslation(["happiness_survey"])
  const welcomeSlide = Object.values(t("welcome_slide.content", { returnObjects: true }, ""))

  const hasLoaded = useSelector(happinessSurveySelectors.getHasLoaded)

  return (
    <>
      <Loader surveyId={happinessSurveyId()} />
      <CountriesLoader />
      <IndustriesLoader />
      <SurveyJobRolesLoader />
      {hasLoaded && (
        <CardWrapper bg="light.0" mt={[5, 5, 6]} mb={[6, 5]} elevation={8} width={1 / 1}>
          <Flex flexDirection="column" p={4}>
            <Flex width={1 / 1} justifyContent="center" mb={[2, 5]}>
              <SiteLogo />
            </Flex>
            <Text py={2} color="dark.1" fontSize={[2, 4]}>
              {t("welcome_slide.select_language", "")}
            </Text>
            <Flex flexDirection="row">
              <Flex width="100%" justifyContent="center" alignItems="center">
                <SelectShaped
                  borderRadius={0}
                  bg="light.0"
                  id="language"
                  defaultValue=""
                  width="100%"
                  height={["49px", "52px"]}
                  value={i18n.language}
                  onChange={e => i18n.changeLanguage(e.target.value)}
                >
                  {languages?.map(lang => (
                    <option key={lang.code} value={lang.code}>
                      {lang.icon} {lang.nativeName}
                    </option>
                  ))}
                </SelectShaped>
              </Flex>
            </Flex>
            <Text fontSize={[4, 5]} fontWeight="600" color="dark.2" mb={[2, 3]} mt={[2, 3]}>
              {t("welcome_slide.title", "")}
            </Text>
            <ContentWrapper>
              <Paragraph fontSize={3} color="dark.1" mb={3}>
                {welcomeSlide[0]}
              </Paragraph>
              <Paragraph fontSize={[4, 5]} color="primary.0" fontWeight="600" mb={1}>
                {welcomeSlide[1]}
              </Paragraph>
              <StyledOl>
                <Text as="li" color="dark.1" fontSize={3} mb={[2, 3]}>
                  {welcomeSlide[2]}
                </Text>
                <Text as="li" color="dark.1" fontSize={3} mb={[0, 2]}>
                  {welcomeSlide[3]}
                </Text>
              </StyledOl>
            </ContentWrapper>
          </Flex>
          <SurveyDifference t={t} startSurvey={startSurvey} />
          <VoiceAward t={t} startSurvey={startSurvey} />
          <SponsorLogo arr={logos} />
        </CardWrapper>
      )}
    </>
  )
}

export default Welcome
