import { Box, Button, Card, Flex, Paragraph, Text } from "@engaging-tech/components"
import { useLocation, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import SelectShaped from "../../ui/components/SelectShaped"
import SiteLogo from "../../ui/components/SiteLogo"
import NoSavantaTerritory from "./NoSavantaTerritory"

const ContentWrapper = styled(Box)`
  & > p:last-child {
    margin-bottom: 0;
  }
`

const WelcomeSavanta = ({ languages, startRoute, title, onLoad, setSavantaId, setSavantaTerritory }) => {
  const router = useRouter()
  const search = new URLSearchParams(useLocation().search)
  const urlSavantaId = new URLSearchParams(search).get("id")
  const savantaTerritory = new URLSearchParams(search).get("territory")

  const startSurvey = id => {
    onLoad(id)
    router.navigate(startRoute)
  }

  const { t, i18n } = useTranslation(["savanta_survey"])

  useEffect(() => {
    if (urlSavantaId) setSavantaId(urlSavantaId)
    if (savantaTerritory) setSavantaTerritory(savantaTerritory)
  }, [])

  if (!savantaTerritory) return <NoSavantaTerritory />

  return (
    <>
      <Card bg="light.0" mt={[5, 5, 6]} mb={[6, 5]} elevation={8} width={1 / 1} maxWidth={560}>
        <Flex flexDirection="column" py={3} px={[3, 4]}>
          <Flex width={1 / 1} justifyContent="center" mb={[3, 4]}>
            <SiteLogo />
          </Flex>
          <Text fontSize={5} fontWeight="600" color="dark.2" mb={3} textAlign="center">
            {title}
          </Text>
          <ContentWrapper>
            {Object.entries(t("welcome_slide.share_content", { returnObjects: true }, "")).map(item => (
              <Paragraph color="dark.2" fontSize={3} mb={2} key={item}>
                {item[1]}
              </Paragraph>
            ))}
          </ContentWrapper>
        </Flex>
        <Text py={3} px={[3, 4]}>
          {t("welcome_slide.select_language", "")}
        </Text>
        <Flex width="100%" justifyContent="center">
          <SelectShaped
            borderRadius={0}
            bg="light.0"
            width="90%"
            id="language"
            defaultValue=""
            value={i18n.language}
            onChange={e => i18n.changeLanguage(e.target.value)}
          >
            {languages?.map(lang => (
              <option key={lang.code} value={lang.code}>
                {lang.icon} {lang.nativeName}
              </option>
            ))}
          </SelectShaped>
        </Flex>
        <Flex flexDirection={["column", "row"]} justifyContent="center" px={[3, 4]} py={3}>
          <Button
            variant="primary.0"
            width={["100%", "initial"]}
            my={[1, 0]}
            p={2}
            onClick={() => startSurvey(`${getConfig().surveyIds.savantaWhs}`)}
          >
            {t("choose.wfh_button", "")}
          </Button>
        </Flex>
      </Card>
    </>
  )
}

export default WelcomeSavanta
