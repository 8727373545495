import { CircularProgress } from "@engaging-tech/charts"
import { Box, Flex, Text } from "@engaging-tech/components"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

import usePercentageGraphicAnimation from "../../../hooks/usePercentageGraphicAnimation"
import { getLabelData } from "../../../lib/utils"
import ScoreLabel from "../../surveyResults/components/ScoreLabel"

const PercentageGraphic = ({
  score,
  text,
  isLowest,
  height = 200,
  width = 1 / 1,
  justifyContent = "center",
  maxWidth = "auto",
  maxHeight = "auto",
  size = 400,
  graphicWidth = 160,
  thickness = 0.44,
  noScoreLabel,
  animate = false,
  reverseColor = false,
  color = null,
  noData,
  animationDelay,
  ...props
}) => {
  const containerRef = useRef()
  const currentPercentage = usePercentageGraphicAnimation({
    scoreToAnimateTo: score,
    containerRef,
    deactivate: !animate,
    delay: animationDelay
  })
  const { t } = useTranslation(["results"])

  return (
    <Box
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      mb={2}
      ref={containerRef}
      {...props}
    >
      <Flex
        flexDirection="column"
        justifyContent={justifyContent}
        alignItems="center"
        width={1 / 1}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        <CircularProgress
          px={[2, 0]}
          percentage={animate ? currentPercentage : score ?? 0}
          width={graphicWidth}
          thickness={thickness}
          noData={noData}
          strokeColor={
            color ||
            (reverseColor
              ? getLabelData(100 - (animate ? currentPercentage : score ?? 0)).color
              : getLabelData(animate ? currentPercentage : score ?? 0).color)
          }
          size={size}
        />
        {!score && score !== 0 && (
          <Text textAlign="center">{t("happiness_data_highlight.percentage_graphic.no_data", "")}</Text>
        )}
        {text && (
          <Text textAlign="center" fontWeight={500} color="dark.2" fontSize={3} width={3 / 4}>
            {text}
          </Text>
        )}
        {!noScoreLabel && <ScoreLabel score={score} fontSize={5} />}
        {isLowest && (
          <Text color="#BF4630" fontSize={3} fontWeight={500} width="auto">
            {t("happiness_data_highlight.percentage_graphic.lowest", "Lowest").toUpperCase()}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export default PercentageGraphic
