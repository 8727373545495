import { Button, Flex, Modal, Text, Utils } from "@engaging-tech/components"
import { useLocation, useRouter } from "@engaging-tech/routing"
import React, { useMemo } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ActionPlanStep } from "../../../../../components/action-plan/action-plan-step"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const BackButton = styled(Button)`
  text-transform: none;
`

const StyledModal = styled(Modal)`
  max-width: 1120px;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  padding: 12px;

  @media only screen and (min-width: 1440px) {
    width: fit-content;

    padding: 36px;
  }
`
const TitleText = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;

  color: #013e4cde;

  margin-bottom: 0;
`

const ActionPlansModal = ({ actionPlanStep, stepResult, stageIndex, setVisibility, isPremium }) => {
  const router = useRouter()
  const location = useLocation()

  const { t } = useTranslation(["results"])

  const isIndividualSurvey = location.pathname.split("/").includes("happiness-survey")

  const questions = useMemo(() => {
    if (!actionPlanStep?.questions?.length || !stepResult?.questions?.length) {
      return []
    }

    const stepQuestionMap = new Map(stepResult.questions.map(q => [q.id, q]))

    return actionPlanStep.questions.map(actionPlanQuestion => {
      const stepQuestion = stepQuestionMap.get(actionPlanQuestion.sixStepsQuestionId)

      if (stepQuestion?.values?.score != null) {
        return {
          ...actionPlanQuestion,
          score: Math.round(stepQuestion.values.score * 10)
        }
      }
      return { ...actionPlanQuestion, score: -1 }
    })
  }, [actionPlanStep?.questions, stepResult?.questions])

  if (!stepResult || !actionPlanStep) return null
  if (!isPremium && isIndividualSurvey) router.navigate(`/premium?lastRoute=${location.pathname}`)

  return createPortal(
    <StyledModal
      onToggle={() => setVisibility(false)}
      bg="light.0"
      my={[0, 5]}
      style={{ overflowX: "hidden" }}
    >
      <Utils.DisableScroll />
      <Flex flexDirection="column" width={1 / 1} style={{ overflowX: "hidden" }}>
        <BackButton
          onClick={() => setVisibility(false)}
          leadingIcon="arrow_back"
          variant="light.0"
          borderRadius={24}
          elevation={1}
        >
          <Text marginRight={4} fontWeight={400} color="dark.2" fontSize={3}>
            {t("action_plan.backButton", "")}
          </Text>
        </BackButton>
        <TitleText>
          {t("action_plan.button", "")} - {t(`action_plan.stage.${stageIndex}.heading`, "")}
        </TitleText>
        <ActionPlanStep step={{ ...actionPlanStep, questions }} score={stepResult.score} />
      </Flex>
    </StyledModal>,
    appRoot()
  )
}

export default ActionPlansModal
