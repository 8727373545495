import React from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import HomepageBlock from "../../ui/components/HomepageBlock"

const meetTheTeamCopy = [
  "WorkL HQ is in London, but Team WorkL is based across the UK and Europe - we’re all about flexible working!"
]

const MeetTheTeam = () => {
  return (
    <HomepageBlock bg="primary.0">
      <Flex flexDirection="column" width={[1 / 1, 1 / 1, 2 / 3]}>
        {meetTheTeamCopy.map(text => (
          <Text color="#fff" fontSize={[5, 6]} mb={4} key={text} width={1 / 1}>
            {text}
          </Text>
        ))}
        <Flex flexDirection="column" width={1 / 1}>
          <Text color="#fff" fontSize={[5, 6]} mb={4}>
            To find out more, become one of our ambassadors, or join the team,{" "}
            <Link
              to={`${getConfig().workL.public.index}/contact-us`}
              newTab
              external
            >
              <Text fontWeight={700} color="#fff" fontSize={[5, 6]}>
                Contact Us Here
              </Text>
            </Link>
          </Text>
        </Flex>
      </Flex>
    </HomepageBlock>
  )
}

export default MeetTheTeam
