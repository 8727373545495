import { connect } from "react-redux"

import WelcomeComponent from "../components/WelcomeSavanta"
import { load, setSavantaId, setSavantaTerritory } from "../store/happinessSurvey.actions"
import { getSurveyLanguages } from "../store/happinessSurvey.selectors"

const mapState = state => ({
  languages: getSurveyLanguages(state)
})
const mapDispatch = dispatch => ({
  onLoad: id => dispatch(load(id)),
  setSavantaId: id => dispatch(setSavantaId(id)),
  setSavantaTerritory: geolocation => dispatch(setSavantaTerritory(geolocation))
})

const WelcomeSavanta = connect(mapState, mapDispatch)(WelcomeComponent)

export default WelcomeSavanta
