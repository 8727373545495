import { CircularProgress, Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import ScoreLabel from "../../features/surveyResults/components/ScoreLabel"
import { getLabelData } from "../../lib/utils"

const CustomFlex = styled(Flex)`
  grid-area: B;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
`
const ScoreContainerTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;

  margin-bottom: 20px;
`

export default function CircularScore({ score }) {
  return (
    <CustomFlex>
      <ScoreContainerTitle>Your overall score for this area:</ScoreContainerTitle>
      <CircularProgress percentage={score} width="50%" strokeColor={getLabelData(score).color} />
      <ScoreLabel textAlign="center" mt={3} score={score} />
    </CustomFlex>
  )
}
