import { gql } from "@apollo/client"

const GET_ALL_ACTION_PLANS = gql`
  query previewActionPlan($surveyId: String!) {
    previewActionPlan(surveyId: $surveyId) {
      message
      status
      body {
        steps {
          questions {
            body {
              questions {
                answers
                id
                title
              }
              resources {
                description
                mediaType
                title
                url
              }
              suggestedActions
              suggestedPhrases
              description
            }
            sixStepsQuestionId
            title
          }
          title
          type
        }
        title
      }
    }
  }
`

export default GET_ALL_ACTION_PLANS
