import {
  Button,
  Flex,
  Icon,
  Paragraph,
  Text,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  vertical-align: middle;
  font-style: italic;
  @media screen and (max-width: 330px) {
    margin: auto;
  }
`

const OrgButton = styled(Button)`
  width: 262px;
  height: 38px;
  align-self: center;
  filter: drop-shadow(0px 4px 4px 0px #00000040);
  border: 4px solid #f1b98b;
  border-radius: 9px;
  @media screen and (max-width: 370px) {
    width: 228px;
    font-size: 12px;
  }
`
const LinkIcon = styled(Icon)`
  transform: rotateZ(130deg);
  :hover {
    cursor: pointer;
    transform: rotateZ(180deg);
  }
`

const StyledButton = styled(Button)`
  background: none;
  box-shadow: none;
  & span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  :active {
    text-decoration: none;
    cursor: pointer;
  }
`

const VoiceAward = ({ t, startSurvey }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const shareContent = Object.values(t("welcome_slide.share_content", { returnObjects: true }, ""))

  const copyToClipboard = () => {
    if (history.location.pathname.includes("/in-app-view")) {
      navigator.clipboard.writeText("https://app.workl.com/in-app-view/happiness-survey/rank-company")
    } else {
      navigator.clipboard.writeText("https://app.workl.com/happiness-survey/rank-company")
    }
  }

  const handleOnclick = () => {
    copyToClipboard()
    dispatch(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Copied to clipboard"
      })
    )
  }
  return (
    <Flex flexDirection="column" bg="secondary.3" width="100%" px={4}>
      <Flex
        width={1 / 1}
        flexDirection={["column", "row"]}
        justifyContent={["center", "space-between"]}
        alignItems="center"
        pb={3}
        pt={3}
      >
        <Flex width={["226px", "236px"]} height={["143", "183px"]}>
          <StyledImg
            src="https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/companylogos/HappiestWorklplaces2024_2.png"
            alt="Happiest Companies Awards"
          />
        </Flex>
        <Flex
          width={["238px", "280px"]}
          height={["143", "183px"]}
          flexDirection="column"
          justifyContent={["center", "flex-end"]}
        >
          <OrgButton
            variant="primary.0"
            m={[3, 5]}
            onClick={() => {
              if (typeof window !== "undefined" && window.gtag) {
                window.gtag("event", "happy_at_work_test", {
                  event_category: "button_click",
                  event_label: "Happy at Work Test"
                })
              }
              startSurvey(`${getConfig().surveyIds.whs}`)
            }}
          >
            {t("choose.who_button", "")}
          </OrgButton>
          <Paragraph
            p={0}
            mb={0}
            color="primary.0"
            fontWeight="700"
            fontSize={[3, 4]}
            width="100%"
            alignSelf="start"
            alignText="start"
          >
            {shareContent[0]}
          </Paragraph>
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <Paragraph color="dark.1" fontSize={3} mb={3}>
          {shareContent[1]}
          <Text fontWeight="600" fontSize={3}>
            {shareContent[2]}
          </Text>
          {shareContent[3]}
        </Paragraph>
        <Paragraph color="dark.1" fontSize={3} mb={2}>
          {shareContent[4]}
        </Paragraph>
        <Flex width={1 / 1} justifyContent="flex-end" alignItems="center" pb={2}>
          <StyledButton
            textAlign="right"
            onClick={() => {
              if (typeof window !== "undefined" && window.gtag) {
                window.gtag("event", "copy_survey_link", {
                  event_category: "button_click",
                  event_label: "Copy Link"
                })
              }
              handleOnclick()
            }}
          >
            <LinkIcon name="link" />
            <Text color="dark.1" fontSize={[2, 3]}>
              {t("welcome_slide.copy_link", "")}
            </Text>
          </StyledButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default VoiceAward
