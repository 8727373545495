/* eslint-disable react-hooks/exhaustive-deps */
import { getConfig } from "@engaging-tech/ssr-config"
import { API, Amplify, graphqlOperation } from "aws-amplify"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

const apiConfig = (isPublic, config) =>
  isPublic
    ? {
        aws_appsync_graphqlEndpoint: config.appSync.publicGatewayURL,
        aws_appsync_authenticationType: config.appSync.publicGatewayAuthType,
        aws_appsync_apiKey: config.appSync.publicGatewayApiKey,
        aws_appsync_region: config.appSync.publicGatewayRegion
      }
    : {
        aws_appsync_graphqlEndpoint: getConfig().appSync.privateGatewayURL,
        aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
        aws_appsync_region: getConfig().appSync.publicGatewayRegion
      }

const useAppSyncQuery = (query, options = {}) => {
  const {
    initAction = null,
    successAction = null,
    failAction = null,
    variables = null,
    enabledOnMount = true,
    isPublic = false
  } = options

  const config = getConfig()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(enabledOnMount)
  const [errors, setErrors] = useState()
  const [data, setData] = useState()

  const getData = async (functionVariables = null) => {
    try {
      setIsLoading(true)
      Amplify.configure(apiConfig(isPublic, config))

      const res = await API.graphql(graphqlOperation(query, functionVariables ?? variables))

      setIsLoading(res.loading)
      setData(res.data)
      if (res.data && successAction) dispatch(successAction(res.data))
      if (res.loading && initAction) dispatch(initAction(res.loading))
    } catch (error) {
      if (error) {
        setIsLoading(false)
        if (failAction) dispatch(failAction(error))
        setErrors(error)
      }
    }
  }

  useEffect(() => {
    if (enabledOnMount) getData()
  }, [])

  return { isLoading, errors, data, getData }
}

export default useAppSyncQuery
